import Swal from "sweetalert2";
export default {
  namespaced: true,
  state: {
    generateShareableLinkLoading: false,
  },
  mutations: {
    SET_GENERATE_SHAREABLE_LINK_LOADING: (state, data) => {
      state.generateShareableLinkLoading = data;
    },
  },
  actions: {
    create: async function (_, payload) {
      this.$http
        .post("invoices/user/" + payload.id + "/create", payload.data)
        .catch((err) => {
          if (!err.accessDenied) {
            Swal.fire(
              "Error",
              "Could not generate invoice, " + err.data.error.message,
              "error"
            );
          }
        });
    },
    get: async function (_, payload) {
      try {
        const res = await this.$http.get("invoices/" + payload.id);
        return res.data.data;
      } catch (err) {
        err;
        return [];
      }
    },
    getByIds: async function (_, payload) {
      try {
        const res = await this.$http.get(
          `invoices?invoices_ids=${encodeURIComponent(
            JSON.stringify(payload.invoices_ids)
          )}&all=${payload.all}`
        );
        return res.data.data;
      } catch (err) {
        err;
        return [];
      }
    },
    generatedInvoices: async function (_, payload) {
      try {
        const res = await this.$http.get(
          "invoices/user/" + payload.id + "/generated"
        );
        if (res) {
          return res.data.data;
        }
      } catch {
        return [];
      }
    },
    serviceInvoices: async function (_, payload) {
      try {
        const res = await this.$http.get(
          `invoices/user/${payload.id}/service?start=${payload.start}&end=${payload.end}`
        );
        if (res) {
          return res.data.data;
        }
      } catch {
        return [];
      }
    },
    delete: function (_, id) {
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .delete(`invoices/${id}`)
          .then(() => {
            resolve(true);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              resolve(false);
            }
          });
      });
    },
    updatePaidStatus: function (_, payload) {
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .put(`invoices/${payload.id}/paid-status`, {
            paid_date: payload.paid_date,
          })
          .then(() => {
            Swal.fire({
              title: "",
              text: "Invoice Updated Successfully",
              icon: "success",
            });
            resolve(true);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: err?.data?.error?.message || "Something went wrong...",
                icon: "error",
              });
              resolve(false);
            }
          });
      });
    },
    generateShareableLink: function ({ commit }, payload) {
      commit("SET_GENERATE_SHAREABLE_LINK_LOADING", payload.id);
      return new Promise((resolve) => {
        this.$http.post("invoices/" + payload.id + "/shareable-link").then(
          (res) => {
            commit("SET_GENERATE_SHAREABLE_LINK_LOADING", false);
            resolve(res.data);
          },
          (err) => {
            commit("SET_GENERATE_SHAREABLE_LINK_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: err?.data?.error?.message || "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    getInvoiceByToken: function (_, token) {
      return new Promise((resolve) => {
        this.$http.get(`public-invoices/${token}`).then(
          (res) => {
            resolve(res.data);
          },
          () => {
            resolve(false);
          }
        );
      });
    },
  },
};
