import Swal from "sweetalert2";

export default {
  namespaced: true,
  state: {
    data: [],
    isLoading: false,
    isSaving: false,
    isSharing: false,
  },
  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
  },
  actions: {
    save: function (context, payload) {
      let data = payload;
      context.commit("SET_STATE", {
        key: "isSaving",
        value: true,
      });
      let vm = this;
      return new Promise(function (resolve, reject) {
        if (!data) {
          return reject("No Data");
        }
        vm.$http
          .post(`documents/folders`, data)
          .then((res) => {
            if (res) {
              context.commit("SET_STATE", {
                key: "isSaving",
                value: false,
              });
              return resolve(res.data);
            }
          })
          .catch((err) => {
            context.commit("SET_STATE", {
              key: "isSaving",
              value: false,
            });
            return reject(err);
          });
      });
    },
    get: function (context, id) {
      context.commit("SET_STATE", {
        key: "isLoading",
        value: true,
      });
      return this.$http
        .get(`documents/folders${id ? "?user_id=" + id : ""}`)
        .then((res) => {
          let data = res.data.data;

          context.commit("SET_STATE", {
            key: "data",
            value: data,
          });
          context.commit("SET_STATE", {
            key: "isLoading",
            value: false,
          });
        });
    },
    download: function (_, doc) {
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .get(`documents/${doc.id}?owner_id=${doc.owner_id}&download=${true}`)
          .then((res) => {
            var fileLink = document.createElement("a");
            fileLink.href = res.data.url;
            fileLink.setAttribute("download", res.data.filename);
            document.body.appendChild(fileLink);
            fileLink.click();
            return resolve(res.data.url);
          });
      });
    },
    view: function (_, doc) {
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .get(`documents/${doc.id}?owner_id=${doc.owner_id}`)
          .then((res) => {
            return resolve(res.data.url);
          });
      });
    },
    shareFiles: function (context, payload) {
      context.commit("SET_STATE", {
        key: "isSharing",
        value: true,
      });

      this.$http
        .post(`documents/share`, payload)
        .then(() => {
          context.commit("SET_STATE", {
            key: "isSharing",
            value: false,
          });
          Swal.fire({
            title: "",
            text: "Document(s) Shared Successfully",
            icon: "success",
          });
        })
        .catch(({ data, accessDenied }) => {
          context.commit("SET_STATE", {
            key: "isSharing",
            value: false,
          });
          if (
            (((data || {}).error || {}).message || "").indexOf(
              "Duplicate entry"
            ) > -1
          ) {
            Swal.fire({
              title: "",
              text: "Document(s) Shared Successfully",
              icon: "success",
            });
          } else {
            if (!accessDenied) {
              Swal.fire({
                title: "Error",
                text: "Something went wrong...",
                icon: "error",
              });
            }
          }
        });
    },
    shareFolders: function (context, payload) {
      context.commit("SET_STATE", {
        key: "isSharing",
        value: true,
      });

      this.$http
        .post(`documents/folders/share`, payload)
        .then(() => {
          context.commit("SET_STATE", {
            key: "isSharing",
            value: false,
          });
          Swal.fire({
            title: "",
            text: "Folder Shared Successfully",
            icon: "success",
          });
        })
        .catch((err) => {
          context.commit("SET_STATE", {
            key: "isSharing",
            value: false,
          });
          if (!err.accessDenied) {
            Swal.fire({
              title: "Error",
              text: "Something went wrong...",
              icon: "error",
            });
          }
        });
    },
    deleteFolder: function (_, payload) {
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .delete(
            `documents/folders/${
              payload.folderId +
              (payload.userId ? "?user_id=" + payload.userId : "")
            }`
          )
          .then(() => {
            resolve(true);
          })
          .catch(() => {
            resolve(false);
          });
      });
    },
    deleteDocument: function (_, id) {
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .delete(`documents/${id}`)
          .then(() => {
            resolve(true);
          })
          .catch(() => {
            resolve(false);
          });
      });
    },
  },
};
