import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const files = require.context("./", false, /\.js$/);
const modules = {};
files.keys().forEach((name) => {
  let mod = name.replace(/(\.\/|\.js)/g, "").split("/");
  if (mod != "index") {
    modules[mod[mod.length - 1]] = files(name).default;
  }
});

export default new Vuex.Store({
  state: {
    isLoading: false,
    isSaving: false,
  },
  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
  },
  actions: {},
  modules: modules,
});
