import Swal from "sweetalert2";

export default {
  namespaced: true,
  state: {
    sessions: {
      loading: false,
      data: [],
    },
    sessionLoading: false,
    session: null,
    createSessionLoading: false,
  },
  mutations: {
    SET_SESSIONS_LOADING: (state, data) => {
      state.sessions.loading = data;
    },
    SET_SESSIONS_DATA: (state, data) => {
      state.sessions.data = data;
    },
    SET_SESSION_LOADING: (state, data) => {
      state.sessionLoading = data;
    },
    SET_SESSION: (state, data) => {
      state.session = data;
    },
    SET_CREATE_SESSION_LOADING: (state, data) => {
      state.createSessionLoading = data;
    },
  },
  actions: {
    getSessions: function ({ commit }, payload) {
      const vm = this;
      commit("SET_SESSIONS_LOADING", true);
      return new Promise((resolve) => {
        return vm.$http.get(`okaya/${payload.user_id}/sessions`).then(
          (res) => {
            commit("SET_SESSIONS_DATA", res.data.data);
            commit("SET_SESSIONS_LOADING", false);
            resolve(res.data);
          },
          (err) => {
            commit("SET_SESSIONS_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  err?.data?.error?.message ||
                  err?.data?.message ||
                  "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    createSession: function ({ commit }, payload) {
      const vm = this;
      commit("SET_CREATE_SESSION_LOADING", true);
      return new Promise((resolve) => {
        return vm.$http.post(`okaya/${payload.user_id}/sessions`, payload).then(
          (res) => {
            commit("SET_CREATE_SESSION_LOADING", false);
            resolve(res.data);
          },
          (err) => {
            commit("SET_CREATE_SESSION_LOADING", false);
            if (!err.accessDenied) {
              console.log(err);
              Swal.fire({
                title: "Error",
                text:
                  err?.data?.error?.message ||
                  err?.data?.message ||
                  "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    getSession: function ({ commit }, payload) {
      const vm = this;
      commit("SET_SESSION_LOADING", true);
      return new Promise((resolve) => {
        return vm.$http
          .get(`okaya/${payload.user_id}/sessions/${payload.id}`)
          .then(
            (res) => {
              commit("SET_SESSION", res.data);
              commit("SET_SESSION_LOADING", false);
              resolve(res.data);
            },
            (err) => {
              commit("SET_SESSION_LOADING", false);
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    err?.data?.error?.message.error ||
                    err?.data?.error?.message ||
                    err?.data?.message ||
                    "Something went wrong...",
                  icon: "error",
                });
              }
              resolve(false);
            }
          );
      });
    },
  },
};
